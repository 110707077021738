<template>
  <div
    class="back"
    :style="{'background-image': `url(${require('../../assets/clint-mckoy.jpg')})`}"
  >
    <v-dialog
      v-model="dialogLogin"
      persistent
      max-width="600px"
      width="auto"
    >
      <v-container>
        <v-card class="px-4">
          <v-card-title class="headline grey lighten-2">
            MNDB Internal System
          </v-card-title>
          <v-card-text>
            <v-form
              ref="loginForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginUsername"
                    :rules="[rules.required, rules.min]"
                    :disabled="isLoading"
                    hint="At least 3 characters"
                    counter
                    filled
                    label="Username"
                    required
                    @keyup.enter="validate"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="loginPassword"
                    :rules="[rules.required, rules.min]"
                    :disabled="isLoading"
                    :type="'password'"
                    filled
                    name="input-10-1"
                    label="Password"
                    hint="At least 3 characters"
                    counter
                    @keyup.enter="validate"
                  />
                </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  xsm="12"
                />
                <v-spacer />
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="3"
                  xsm="12"
                  align-end
                >
                  <v-btn
                    x-large
                    block
                    :disabled="!valid"
                    color="success"
                    @click="validate"
                  >
                    Login
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>
    <v-container>
      <v-row>
        <div class="text-center">
          <v-dialog
            v-model="dialog.show"
            width="500"
          >
            <v-card>
              <v-card-title class="headline lighten-2">
                <span
                  class="red--text text-uppercase"
                  style="letter-spacing: 0.3rem; color: red"
                > {{ dialog.title }} </span>
              </v-card-title>

              <v-card-text
                align="center"
              >
                {{ dialog.message }}
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="dialog.show = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data: () => ({
    dialogLogin: true,
    dialog: {
      show: false,
      title: '',
      description: ''
    },
    valid: true,
    loginPassword: '',
    loginUsername: '',
    show1: true,
    rules: {
      required: value => !!value || 'Required.',
      min: v => (v && v.length >= 3) || 'Min 3 characters'
    },
    isLoading: false,
    showError: false
  }),
  methods: {
    ...mapActions(['LogIn']),
    async submit () {

    },
    validate () {
      if (this.$refs.loginForm.validate()) {
        this.isLoading = true
        axios.post('/api/v1/users/login', { username: this.loginUsername.toUpperCase(), password: this.loginPassword })
          .then(result => {
            if (result.status === 200) {
              this.isLoading = false
              this.showError = false
              this.LogIn(result.data)
              if (
                result.data.permissions.indexOf('DASHBOARD') > -1 ||
                result.data.permissions.indexOf('DASHBOARD_ADMIN') > -1) {
                this.$router.push('/')
              } else {
                this.$router.push('/support')
              }
            } else {
              this.dialog.show = true
              this.isLoading = false
              this.showError = true
              this.dialog.title = 'ERROR'
              this.dialog.message = result.data.message || 'General error! Please contact system administrator (support@mndb.eu) if problem continue!'
            }
          }).catch((r) => {
            this.dialog.show = true
            this.isLoading = false
            this.showError = true
            this.dialog.title = 'ERROR'
            this.dialog.message = r.data.message
          })
      }
    }
  }
}
</script>

<style scoped>
.back {
  width: 100vw;
  height: 100vh;
  background-size: cover;
}

.v-dialog__container {
  opacity: 0.95;
  display: block;
}

.v-messages__message {
  color: red !important;
}
.theme--light.v-messages {
  color: red !important;
}

.error--text {
  color: red !important;
}
</style>
